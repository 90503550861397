
import { UserLogData } from '@/data/auth/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<UserLogData>,
      required: true,
    },
  },
})
