
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    blocked: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
})
